import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Siesta({ styles, ...props }) {
  const Elements = useComponents()
  const checkboxes_color = styles.forElement("form_subheader_text").color
  const form = React.createRef()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div
        className="formkit-background"
        style={{
          backgroundColor: styles.forElement("background").backgroundColor
        }}
      >
        <div
          className="formkit-background-image"
          style={styles.forElement("background")}
        />
        <div className="formkit-wrapper">
          <div
            className="formkit-container"
            style={styles.forElement("header_background")}
          >
            <div className="formkit-max">
              <div>
                <Elements.Content
                  className="formkit-preheader"
                  name="preheader"
                  defaults={{
                    content: "New Post"
                  }}
                />
                <Elements.Heading
                  className="formkit-header"
                  name="header"
                  defaults={{
                    content: "Learn 10 Strategies to Optimize Your Campaigns"
                  }}
                />
                <Elements.Region className="formkit-subheader" name="subheader">
                  <Elements.Content
                    defaults={{
                      content:
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in augue nisl. In congue purus convallis nibh hendrerit rhoncus."
                    }}
                  />
                </Elements.Region>
                <Elements.Button
                  className="formkit-signup"
                  name="button_link"
                  type="button"
                  onClick={() =>
                    form.current.scrollIntoView({ behavior: "smooth" })
                  }
                  defaults={{ content: "Sign up today" }}
                />
              </div>
              <div className="formkit-image-section">
                <div className="formkit-box">
                  <div
                    className="formkit-box-background"
                    style={styles.forElement("box_background")}
                  />
                  <Elements.Image
                    className="formkit-main-image"
                    name="main_image"
                    size={{ h: 450, w: 285 }}
                  />
                </div>
                <div className="formkit-subpicture">
                  <div className="formkit-arrow">
                    <svg
                      width="68"
                      height="81"
                      viewBox="0 0 68 81"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        style={styles.forElement("arrow")}
                        stroke="currentColor"
                        d="M2 80.5166C4.66667 64.8499 20.6 28.1166 63 6.5166"
                        strokeWidth="4"
                      />
                      <path
                        style={styles.forElement("arrow")}
                        stroke="currentColor"
                        d="M42.3691 2L65.1441 5.20971L62.6321 23.0336"
                        strokeWidth="4"
                      />
                    </svg>
                  </div>
                  <Elements.Content
                    className="formkit-caption"
                    name="caption"
                    defaults={{
                      content:
                        "Join 6,000+ designers, get your next design inspiration delivered right to your inbox"
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="formkit-border" style={styles.forElement("border")} />
          <div
            className="formkit-flex formkit-container"
            style={styles.forElement("content_background")}
          >
            <div className="formkit-max formkit-main-content">
              <Elements.Heading
                tag="h2"
                className="formkit-content-header"
                name="content_header"
                defaults={{
                  content:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in augue nisl. In congue purus convallis nibh hendrerit rhoncus."
                }}
              />
              <Elements.Region className="formkit-content" name="content">
                <Elements.Content
                  defaults={{
                    content:
                      "Pellentesque ut euismod sem. Etiam iaculis varius metus vel sodales. Fusce venenatis, elit in convallis feugiat, risus augue tempus urna, ut sodales tortor metus posuere ante. Curabitur vitae enim dignissim, vehicula odio a, maximus felis. Aenean metus sapien, pulvinar quis consequat ac, mollis sit amet ipsum. In lectus enim, fringilla a nunc vel, mattis viverra tortor. Proin neque quam, iaculis sit amet dictum quis, consectetur in augue. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nulla hendrerit pharetra risus ac auctor."
                  }}
                />
              </Elements.Region>
            </div>
            <div
              className="formkit-form-background formkit-form-bg-max"
              style={{
                backgroundColor: styles.forElement("form_background")
                  .backgroundColor
              }}
              ref={form}
            >
              <div
                className="formkit-form-background-image"
                style={styles.forElement("form_background")}
              />
              <div className="formkit-form-max">
                <Elements.Heading
                  className="formkit-form-header"
                  tag="h2"
                  name="form_header"
                  defaults={{
                    content: "Learn 10 Strategies to Optimize Your Campaigns"
                  }}
                />
                <Elements.Region
                  className="formkit-form-subheader"
                  name="form_subheader"
                >
                  <Elements.Content
                    defaults={{
                      content:
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. onsectetur adipiscing eli"
                    }}
                  />
                </Elements.Region>
                <Elements.Form>
                  <Elements.Errors />
                  <Elements.CustomFields
                    style={{
                      color: checkboxes_color
                    }}
                  >
                    <Elements.AddFieldButton />
                    <Elements.Button
                      name="submit"
                      group="button"
                      defaults={{ content: "Sign up today" }}
                    />
                  </Elements.CustomFields>
                </Elements.Form>
                <Elements.Content
                  name="disclaimer"
                  defaults={{
                    content: "We respect your privacy. Unsubscribe at any time."
                  }}
                />
              </div>
            </div>
          </div>
          <Elements.BuiltWith background="background" image="background" />
        </div>
      </div>
    </Elements.LandingPage>
  )
}

Siesta.style = "landing_page"
Siesta.categories = ["eBook", "Product"]
Siesta.thumbnail = ""
Siesta.preview = "https://demo.ck.page/siesta"
Siesta.fields = [
  {
    name: "last_name",
    label: "Your last name"
  },
  {
    name: "first_name",
    label: "Your first name"
  },
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Siesta, { name: "Siesta" })
